import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import ContentIntelligenceWrapper from "@components/layouts/contentIntelligenceWrapper";
import HomePage from "@components/contentintelligence/homePage";

export default function ContentIntelligenceHome(): ReactElement {
    return (
        <ContentIntelligenceWrapper>
            <HomePage />
        </ContentIntelligenceWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
